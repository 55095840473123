<template>
  <div class="subscribe-order-info-block" v-loading="loading">
    <PageTitle title="訂單發票資訊" btnPlain @btnClick="editOrderInvoice" />
    <BaseElForm
      class="form"
      label-position="left"
      label-width="150px"
    >
      <BaseElFormItem label="Email">
        <p>{{ displayData.email || '-' }}</p>
      </BaseElFormItem>
      <BaseElFormItem label="公司抬頭">
        <p>{{ displayData.buyerName || '-' }}</p>
      </BaseElFormItem>
      <BaseElFormItem label="統一編號">
        <p>{{ displayData.buyerIdentifier || '-' }}</p>
      </BaseElFormItem>
    </BaseElForm>
    <EditOrderInvoiceDialog
      v-if="modal.editInvoice"
      :order="orderData"
      @close="modal.editInvoice = false"
      @confirm="onComfirmInvoice"
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import { get } from 'lodash'
import EditOrderInvoiceDialog from './EditOrderInvoiceDialog.vue'
import { UpdateSubscriptionOrderInvoice } from '@/api/subscribe'
import { useShop } from '@/use/shop'
import { useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'SubscribeInvoiceBlock',
  components: {
    PageTitle,
    EditOrderInvoiceDialog,
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const route = useRoute()
    const loading = ref(false)
    const modal = reactive({
      editInvoice: false,
    })
    const displayData = computed(() => {
      const data = get(props.orderData, 'invoiceIssueInfo')

      const email = get(data, 'email') || '-'
      const buyerName = get(data, 'buyerName') || '-'
      const buyerIdentifier = get(data, 'buyerIdentifier') || '-'
      return {
        email,
        buyerName,
        buyerIdentifier,
      }
    })

    const editOrderInvoice = () => {
      modal.editInvoice = true
    }
    const onComfirmInvoice = async (formData) => {
      if (loading.value) return
      loading.value = true
      const payload = {
        shopId: shopId.value,
        id: get(route.params, 'id'),
        ...formData,
      }
      const [res, err] = await UpdateSubscriptionOrderInvoice(payload)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
      emit('refresh')
    }

    return {
      editOrderInvoice,
      modal,
      displayData,
      onComfirmInvoice,
      loading,
    }
  },
})
</script>

<style lang="postcss" scoped>
.el-form-item {
  @apply mb-[4px] text-sub;
}
.form {
  ::v-deep .el-form-item__label {
    @apply leading-[20.27px] text-gray-60 font-normal;
  }
  ::v-deep .el-form-item__content {
    @apply leading-[20.27px] text-gray-100;
  }
}
</style>
