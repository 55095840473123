<template>
  <div class="external-transaction-activity-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="期數" prop="periodNumber" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.periodNumber || '-' }} <br>{{ scope.row.isCurrent ? '(當期)': '' }}</p>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="期數起始日-結束日" prop="timeRange" align="center" />
      <BaseElTableColumn label="扣款紀錄編號" prop="paymentNumber" align="center" />
      <BaseElTableColumn label="原訂扣款日" prop="expectedOrderPaymentDate" align="center" />
      <BaseElTableColumn label="實際扣款日" prop="paymentDate" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.paymentDate || '-' }}</p>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="扣款金額" prop="paymentAmount" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.paymentAmount || '-' }}</p>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="退款金額" prop="refundAmount" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.refundAmount || '-' }}</p>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="退款日" prop="refundDate" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.refundDate || '-' }}</p>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="扣款狀態" prop="paymentStatus" align="center" fixed="right">
        <template slot-scope="scope">
          <Tag :type="paymentStatus(scope.row.status, 'tagType')">{{ paymentStatus(scope.row.status, 'label') }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="orderInvoiceConfig" label="發票種類" prop="buyerType" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.buyerType ? invoiceType(scope.row.buyerType, 'label').substring(0, 2) : '-' }}</p>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="orderInvoiceConfig" label="發票狀態" prop="invoiceStatus" align="center">
        <template slot-scope="scope">
          <Tag v-if="scope.row.invoiceStatus" :type="invoiceStatus(scope.row.invoiceStatus, 'tagType')">{{ invoiceStatus(scope.row.invoiceStatus, 'label') }}</Tag>
          <Tag v-else :type="invoiceStatus('pending', 'tagType')">{{ invoiceStatus('pending', 'label') }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="orderInvoiceConfig" label="發票號碼" prop="invoiceNo" align="center" />
      <BaseElTableColumn label="操作" align="center" fixed="right" width="140">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            editBtn="編輯發票"
            :hideEdit="!orderInvoiceEnable"
            :hideCopy="false"
            copyBtn="編輯"
            @edit="onEditInvoice(scope.row)"
            @copy="onEdit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import { defineComponent, computed, reactive } from 'vue'
import dayjs from '@/lib/dayjs'
import { get, map } from 'lodash'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { formatDate } from '@/utils/date'
import { subscribeOrderPaymentStatusConfig, invoiceStatusConfig, invoiceTypeConfig } from '@/config/subscribe'

export default defineComponent({
  name: 'SubscribeCombineItemTable',
  components: {
    EmptyBlock,
    Tag,
  },
  props: {
    tableData: { type: Array, default: () => [] },
    orderInvoiceConfig: { type: Object },
    orderInvoiceEnable: { type: Boolean, default: false },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const {
          id,
          periodNumber,
          price,
          status,
          refundDate,
          refundAmount,
          paymentAmount,
          paymentDate,
          expectedOrderPaymentDate,
          paymentNumber,
          startDate,
          endDate,
          paymentType,
          refundType,
          note,
          invoiceRecord,
          paymentOrigin,
          refundOrigin,
        } = item
        return {
          id,
          periodNumber,
          isCurrent: dayjs().isBetween(startDate, endDate, 'day', '[)'),
          price,
          status,
          refundDate: refundDate ? formatDate(refundDate, 'YYYY/MM/DD') : null,
          refundAmount: refundAmount,
          paymentAmount: paymentAmount,
          paymentDate: paymentDate ? formatDate(paymentDate, 'YYYY/MM/DD') : null,
          expectedOrderPaymentDate: expectedOrderPaymentDate ? formatDate(expectedOrderPaymentDate, 'YYYY/MM/DD') : '-',
          paymentNumber: paymentNumber,
          timeRange: `${formatDate(startDate, 'YYYY/MM/DD')} - ${formatDate(endDate, 'YYYY/MM/DD')}`,
          paymentType,
          refundType,
          note,
          buyerType: get(invoiceRecord, 'buyerType'),
          buyerName: get(invoiceRecord, 'buyerName'),
          buyerIdentifier: get(invoiceRecord, 'buyerIdentifier'),
          email: get(invoiceRecord, 'email'),
          invoiceStatus: get(invoiceRecord, 'status'),
          invoiceNo: get(invoiceRecord, 'invoiceNo') || '-',
          invoiceNote: get(invoiceRecord, 'note'),
          paymentOrigin,
          refundOrigin,
        }
      })
    })
    // const currentPeriod = computed(() => {
    //   return ''
    // })
    const paymentStatus = (status, attr) => {
      return get(subscribeOrderPaymentStatusConfig[status], attr)
    }
    const invoiceType = (type, attr) => {
      return get(invoiceTypeConfig[type], attr)
    }
    const invoiceStatus = (status, attr) => {
      return get(invoiceStatusConfig[status], attr)
    }
    const onEdit = (row) => {
      emit('handleClickItem', 'edit', row)
    }
    const onEditInvoice = (row) => {
      emit('handleClickItem', 'editInvoice', row)
    }

    return {
      modal,
      displayData,
      get,
      dayjs,
      onEdit,
      onEditInvoice,
      subscribeOrderPaymentStatusConfig,
      paymentStatus,
      invoiceType,
      invoiceStatus,
      // currentPeriod,
    }
  },
})
</script>
